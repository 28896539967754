import { GameConfig, printConfig, _defaultConfig } from "./config";
import { IFallingObject } from "./falling-object";
import { GameStarter } from "./game";

const defaultFallingObject: IFallingObject = {
	doCatch: true,
	horizontalSpeed: "0",
	verticalSpeed: "500",
	startRotation: "0",
	scale: "1",
	collisionScale: "1",
	sprite: "",
	index: -1,
  points: 1,
  bounceHeight: "1",
  changeOfSpawning: 1,
  rotationSpeed: "0",
};

const customConfig: any = getSettingsFromURI(location.search);

let config: any = _defaultConfig;

if(customConfig)
for(const [key, value] of Object.entries(customConfig)){
    config[key] = value;
}

let c = config as GameConfig;
config.objects = [...parseFallingObjects(config.doBounce || "", true), ...parseFallingObjects(config.doNotBounce || "", false)];

if (c.ballSprite) {
	config.objects.push(<IFallingObject>{
		collisionScale: c.ballCollisionScale.toString(),
		doCatch: true,
		horizontalSpeed: `${c.minVelocityHorizontal}-${c.maxVelocityHorizontal}`,
		verticalSpeed: `${c.minVelocityVertical}-${c.maxVelocityVertical}`,
		startRotation: "0",
		scale: c.minBallScale && c.maxBallScale ? `${c.minBallScale}-${c.maxBallScale}` : `${c.ballScale}`,
		sprite: c.ballSprite,
    points: c.bouncePoints,
    bounceHeight: `${c.minBounceHeight}-${c.maxBounceHeight}`,
    rotationSpeed: 0,
    changeOfSpawning: 1,
    index: 0,

	});
}

config.objects.map((o, i) => {
	o.index = i;
});

printConfig(_defaultConfig);

console.log(config)

const game = new GameStarter(<GameConfig>config);

function getSettingsFromURI(uri: string){
    let settingString;
    const split = uri.split("&")
    for(let s of split){
      if(s[0] === "s"){
        settingString = atob(s.split("=")[1]);
      }
    }

    if(settingString === undefined){
      return null;
    }

    let jsonString = "{";
    let settings: string[] = settingString.split("\n");

    settings.forEach(s => {
      const keyAndValue = s.split("=");
      if(keyAndValue.length === 1){
        return;
      }


      const key = keyAndValue[0].trim();
      const value = keyAndValue[1].trim();


      if(value === "default" || key === "cache"){
          return;
      }

      let line = '"' + key + '": ';

      if(isString(value)){
          line += '"' + value + '"';
      } else {
          line += value;
      }
      if(settings.indexOf(s) < (settings.length - 2)){
        line += ",\n";
      }

      jsonString += line;
    });

    jsonString += "}";
    return JSON.parse(jsonString);
    
}



function parseFallingObjects(value: string, doCatch: boolean) {
	const objects = value.split("}");

	let parsed: IFallingObject[] = [];

	const props = [
		"sprite",
		"horizontalSpeed",
		"verticalSpeed",
    "bounceHeight",
		"scale",
		"collisionScale",
		"startRotation",
    "rotationSpeed",
    "points",
    "changeOfSpawning"
	];

	objects.forEach((o, i) => {
		o = o.trim();
		if (o.length === 0) {
			return parsed;
		}

		let object = { ...defaultFallingObject };
		let properties = o.split("{")[1].split(",");

		properties.forEach((property, i) => {
			if (props[i] === "startRotation") {
				if(property.trim() === "true"){
					object[props[i]] = "0-360";
				}
				else if(property.trim() === "false"){
					object[props[i]] = "0"
				}
				else {
					object[props[i]] = property;
				}
			} else if (props[i] === "points") {
				object[props[i]] = +property;
			} else {
				object[props[i]] = property.trim();
			}
		});

		object.doCatch = doCatch;

		object.index = i;

for(let i = 0; i < object.changeOfSpawning; i++){
			parsed.push(object);
		}	
  });

	return parsed;
}

function isString(value: string){
    if(!isNaN(Number(value))|| value === "true" || value === "false"){
        return false;
    }
    return true;
}




import Phaser, { Game } from "phaser";
import { Catcher } from "./catcher";
import { GameConfig } from "./config";
import { BouncyBall } from "./falling-object";
import { Platform } from "./Platform";
import WebFontFile from "./webfontload";

export class GameStarter {
	imageLoadCounter = 1;
	config: GameConfig;
	path: string;
	width: number;
	height: number;

	constructor(config: GameConfig) {
		this.width = window.innerWidth * window.devicePixelRatio;
		this.height = window.innerHeight * window.devicePixelRatio;
		this.config = config;
		this.path = config.path;

		document.addEventListener("DOMContentLoaded", () => {
			this.loadImages();
		});
	}

	loadImages() {
		this.loadImage("catcher", this.config.plankSprite);

		const unique = [...new Set(this.config.objects)];

		this.imageLoadCounter += unique.length;

		unique.forEach((object, i) => {
			this.loadImage(object.sprite, object.sprite);
		})
	}

	loadImage(key: string, src: string) {
		let img = new Image();
		img.id = key;
		img.hidden = true;
		img.onload = () => {
			document.body.append(img);
			this.imageReady();
		};
		img.onerror = () => {
			console.error("Img " + key + " with source " + src + " not found");
			document.body.append(img);
			this.imageReady();
		};
		img.src = this.path + src;
	}

	init() {
		// const container = document.getElementById("container");
		const phaserConfig: Phaser.Types.Core.GameConfig = {
			type: Phaser.CANVAS,
			width: this.width,
			height: this.height,
			//   parent: container ? container : undefined,
			scene: [new BounceGame(this.config, this.width, this.height)],
			render: {
				transparent: true,
			},
			physics: {
				default: "arcade",
				arcade: {
					debug: BounceGame.config.debug,
					debugShowVelocity: true,
					// fixedStep: true,
				},
			},
			scale: {
				mode: Phaser.Scale.FIT,
			},
			fps: {
				smoothStep: true,
			},
			audio: {
				disableWebAudio: true,
			},
		};

		const game = new Game(phaserConfig);
	}

	imageReady() {
		this.imageLoadCounter--;
		if (this.imageLoadCounter === 0) {
			this.init();
		}
	}
}

export class BounceGame extends Phaser.Scene {
	static config: GameConfig;
	static width: number;
	static height: number;

	tapToPlay?: Phaser.GameObjects.Text;

	platform: Platform;
	fallingObjects: BouncyBall[] = [];
	colliding: BouncyBall[] = [];

	spawnTimer: number = 0;
	speedIncreaseTimer: number = 0;
	speed: number = 1;

	score: number = 0;

	objectCountIncreaseTimer: number = 0;

	playing: boolean = false;

	lives: number | undefined = undefined;


	catcherEnabled: boolean = false;
	maxObjects: number = 0;

	constructor(config: GameConfig, width: number, height: number) {
		super({});
		BounceGame.config = config;
		this.platform = new Platform();
		this.lives = BounceGame.config.lives;

		BounceGame.width = width;
		BounceGame.height = height;

		this.maxObjects = BounceGame.config.minFallingObjects;
	}

	preload() {
		this.load.path = BounceGame.config.path;

		this.load.addFile(new WebFontFile(this.load, BounceGame.config.font));
		
		const catcher = <HTMLImageElement>document.getElementById("catcher");
		this.textures.addImage("catcher", catcher);

		if(BounceGame.config.objects){
			new Set(BounceGame.config.objects).forEach((object, i) => {
				const img = <HTMLImageElement>(
					document.getElementById(object.sprite)
				);
				this.textures.addImage(object.sprite, img);
				// document.body.removeChild(img);
			})
		}
	}

	catcher?: Catcher;
	particles: any = {};


	create() {
		if(this.lives)
		this.platform.sendLives(this.lives);

		this.platform.preloadSounds(BounceGame.config);
		this.scale.displaySize.resize(BounceGame.width, BounceGame.height);

		this.catcher = new Catcher(this);


		this.physics.world.setBoundsCollision(true, true, false, false);

		new Set(BounceGame.config.objects).forEach((object, i) => {
			const p = this.add.particles(object.sprite)
			this.particles[object.sprite] = p;
		})


		if (BounceGame.config.ballsCollide)
			this.physics.add.collider(
				this.colliding,
				this.colliding,
				(b1, b2) => {

					const rotationSpeed = ((b1 as BouncyBall).rotationSpeed + (b2 as BouncyBall).rotationSpeed)/2;
					(b1 as any).rotationSpeed = rotationSpeed;
					(b2 as any).rotationSpeed = rotationSpeed;

					if (BounceGame.config.simplePhysics) {
						if (Math.abs(b1.body.y) < Math.abs(b2.body.y)) {
							if (!(b1 as any).justCollided) {
								(b1 as any).bounce();
								this.playSound("ballsCollide");
							}
						} else {
							(b2 as any).bounce();
							this.playSound("ballsCollide");
						}
					} else {
						this.playSound("ballsCollide");
					}
				},
				undefined,
				BounceGame
			);

		this.physics.world.on(
			"worldbounds",
			(body: Phaser.Physics.Arcade.Body) => {
				if (body.y > 0 && body.y < BounceGame.height)
					this.playSound("wallBounce");
			}
		);

		if (BounceGame.config.simplePhysics) {
			this.physics.add.overlap(
				this.catcher,
				this.fallingObjects,
				(catcher, faller) => this.handleBounce(faller)
			);
		} else {
			this.physics.add.collider(
				this.catcher,
				this.fallingObjects,
				(catcher, faller) => this.handleBounce(faller)
			);
		}

		this.input.on(
			"pointermove",
			(pointer: any) => {
				if (this.catcher && this.catcherEnabled) {
					this.catcher.updatePosition(pointer.x);
				}
			},
			this
		);

		this.input.on(
			"pointerup",
			() => {
				this.startGame();
			},
			this
		);

		let splashScreen = document.getElementById("image") as HTMLImageElement;
		if (splashScreen) {
			splashScreen.src =
				BounceGame.config.path + "/" + BounceGame.config.splashSprite;

			splashScreen.style.width = window.innerWidth + "px";
			splashScreen.style.top =
				(window.innerHeight * BounceGame.config.splashY) / 100 + "px";
			splashScreen.style.opacity = (BounceGame.config.splashOpacity / 100).toString()
			splashScreen.style.transform =
				"translateY(-50%) scale(" + BounceGame.config.splashScale + ")";
		}

		// document.getElementById(
		// 	"score"
		// )!.style.font = `${BounceGame.config.fontSize}px ${BounceGame.config.font}`;
		// document.getElementById("score")!.style.color =
		// 	BounceGame.config.fontColor;
		// document.getElementById("score")!.style.transition = "";
		// document.getElementById("score")!.style.transform = `translateY(-${
		// 	20 + BounceGame.config.fontOffsetY
		// }px) translateY(-100%)`;
		// document.getElementById("score")!.style.top = `${
		// 	20 + BounceGame.config.fontOffsetY
		// }px`;

		this.platform.init(this);
	}

	timer = 0;

	update(time: number, delta: number): void {
		if (this.playing === false) {
			return;
		}

		this.timer += delta;

		if (
			BounceGame.config.scoreType === "time" ||
			BounceGame.config.scoreType === "timeandpoints"
		) {
			this.score += delta * BounceGame.config.bonusPerMs;

			if(this.timer > 1000){
				this.timer = 0;
				this.platform.sendScore(this.score, false);
			} 
		}

		this.speedIncreaseTimer += delta;
		this.objectCountIncreaseTimer += delta;

		if (this.speedIncreaseTimer > BounceGame.config.speedIncreaseInterval) {
			this.speedIncreaseTimer = 0;
			this.speed += BounceGame.config.speedIncrease;
		}

		if (
			this.objectCountIncreaseTimer >
				BounceGame.config.objectIncreaseInterval &&
			this.maxObjects < BounceGame.config.maxFallingObjects
		) {
			this.maxObjects += 1;
			this.objectCountIncreaseTimer = 0;
			this.spawnFallingObject();
		}

		// Check falling object position and stuff
		this.fallingObjects.forEach((fallingObject) => {
			fallingObject.update(delta);
			if (
				fallingObject.body.velocity.y < 0 &&
				fallingObject.y < fallingObject.displayHeight / 2
			) {
				fallingObject.body.velocity.y = -fallingObject.body.velocity.y;
				this.playSound("wallBounce");
			}

			if (fallingObject.withinReach) {
				if (
					fallingObject.y >
					BounceGame.height + fallingObject.displayHeight
				) {
					if(!fallingObject.config.doCatch){
						this.updateScore(fallingObject.config.points, true)
						this.fallingObjects.splice(
							this.fallingObjects.indexOf(fallingObject),
							1
						);
						fallingObject.destroy();
						this.spawnFallingObject();

						return;
					}
					this.playSound("ballDown");
					fallingObject.withinReach = false;

					if (BounceGame.config.freezeOnMistake) {
						this.loseLife();

						this.physics.pause();
						this.playing = false;
						this.catcher?.flicker();
						this.fallingObjects.forEach((f) => {
							if (f !== fallingObject) f.pause();
						});
						setTimeout(() => {
							if (this.isGameOver) {
								return;
							}

							this.fallingObjects.splice(
								this.fallingObjects.indexOf(fallingObject),
								1
							);
							fallingObject.destroy();

							this.fallingObjects.forEach((f) => f.resume());
							this.physics.resume();
							this.playing = true;
						}, BounceGame.config.freezeTime);
					} else {
						this.fallingObjects.splice(
							this.fallingObjects.indexOf(fallingObject),
							1
						);

						fallingObject.destroy();
						this.loseLife();
						this.catcher?.flicker();
					}
				}
			}
		});
	}

	play() {
		this.isGameOver = false;
	}

	screenHasBeenTapped: boolean = false;

	startGame() {
		if (this.playing || this.isGameOver) {
			return;
		}
		if (this.screenHasBeenTapped) {
			return;
		}

		this.platform.gamestarted();

		let splashScreen = document.getElementById("image");
		if (splashScreen) {
			splashScreen.hidden = true;
		}
		this.screenHasBeenTapped = true;
		this.catcherEnabled = true;

		this.catcher?.appear();

		this.playing = true;

		this.spawnFallingObject(BounceGame.config.minFallingObjects);
	}


	spawnFallingObject(loop: number = 1) {
		const delay =
			Math.random() *
				(BounceGame.config.spawnDelayMax -
					BounceGame.config.spawnDelayMin) +
			BounceGame.config.spawnDelayMin;

		const rando = BounceGame.config.objects[Math.round((BounceGame.config.objects.length - 1)  * Math.random())];

		console.log(rando)

		setTimeout(() => {
			if (this.isGameOver) {
				return;
			}
			const fallingObject = new BouncyBall(rando, this);
			this.playSound("spawn");

			this.fallingObjects.push(fallingObject);

			if(fallingObject.config.doCatch){
				this.colliding.push(fallingObject);
			}

			loop -= 1;
			if (loop > 0) {
				this.spawnFallingObject(loop);
			}
		}, delay);
	}
	

	updateScore(plus: number, force: boolean) {
		this.score += plus;
		this.platform.sendScore(this.score, force);
	}

	loseLife() {
		const timeOut = BounceGame.config.freezeOnMistake ? (BounceGame.config.freezeTime || 0) : 0;

		if (this.lives === undefined) {
			if (this.fallingObjects.length > 0) {
				setTimeout(() => {
					if (this.isGameOver) {
						return;
					}
					this.playing = true;
					this.spawnFallingObject();
				}, timeOut);
			} else {
				setTimeout(() => {
					this.gameOver();
				}, timeOut);
			}
		} else {
			this.lives -= 1;
			this.platform.sendLives(this.lives);

			if (this.lives > 0) {
				setTimeout(() => {
					if (this.isGameOver) {
						return;
					}
					this.spawnFallingObject();
				}, timeOut);
			} else {
				setTimeout(() => {
					this.gameOver();
				}, timeOut);
			}
		}
	}

	isGameOver: boolean = false;

	gameOver() {
		if(this.playing === false){
			return;
		}
		this.isGameOver = true;
		this.playing = false;
		this.physics.pause();

		this.platform.gameover(Math.round(this.score));

		this.speed = 1;
		this.spawnTimer = 0;
		this.objectCountIncreaseTimer = 0;
		this.speedIncreaseTimer = 0;
		this.maxObjects = BounceGame.config.minFallingObjects;
	}

	restart() {
		this.physics.resume();

		if(this.lives)
		this.platform.sendLives(this.lives);

		this.fallingObjects.forEach((f) => f.destroy(true));
		this.fallingObjects.splice(0, this.fallingObjects.length);

		this.score = 0;
		this.updateScore(0, true);

		if (this.catcher) {
			this.catcher.reset();
		}

		this.lives = BounceGame.config.lives;


		let splashScreen = document.getElementById("image");
		if (splashScreen) {
			splashScreen.hidden = false;
		}
		this.screenHasBeenTapped = false;

		setTimeout(() => {
			if (this.catcher) this.catcher.reset();
			this.platform.ready();
		}, 50);
	}

	handleBounce(faller: Phaser.Types.Physics.Arcade.GameObjectWithBody) {
		if (this.playing === false) {
			return;
		}
		const fallingObject: BouncyBall = <BouncyBall>faller;
		if (fallingObject.withinReach === false) {
			return;
		}


		if (fallingObject.justBounced) {
			return;
		}


		this.playSound("plankBounce");

		if(fallingObject.config.doCatch){
			
			fallingObject.bounce();
			if (
				BounceGame.config.scoreType === "points" ||
				BounceGame.config.scoreType === "timeandpoints"
			) {
				this.updateScore(fallingObject.config.points, true);
			}
		} else {
			
			fallingObject.withinReach = false;
			if (BounceGame.config.freezeOnMistake) {

				fallingObject.destroy();
				this.fallingObjects.splice(this.fallingObjects.indexOf(fallingObject));

				this.physics.pause();
				this.playing = false;
				this.catcher?.flicker();
				this.fallingObjects.forEach((f) => {
					if (f !== fallingObject) f.pause();
				});
				this.loseLife();

				setTimeout(() => {
					if (this.isGameOver) {
						return;
					}

					this.spawnFallingObject();

					this.fallingObjects.forEach((f) => f.resume());
					this.physics.resume();
					this.playing = true;
				}, BounceGame.config.freezeTime);
			} else {
				fallingObject.destroy();
				this.fallingObjects.splice(this.fallingObjects.indexOf(fallingObject));

				this.catcher?.flicker();
				this.loseLife();
			}
		}
		

		if (this.catcher) {
			if (this.tweens.isTweening(this.catcher)) {
				this.tweens.getTweensOf(this.catcher).forEach((tween) => {
					this.catcher!.alpha = 1;
					tween.stop();
				});
				this.tweens.add({
					targets: [this.catcher],

					y: {
						to: this.catcher.startY,
						from:
							this.catcher.startY +
							BounceGame.config.plankBounceDown,
					},
					ease: "in",
					duration: BounceGame.config.plankBounceDownTime,
				});
				return;
			}
			this.tweens.add({
				targets: [this.catcher],

				y: {
					from: this.catcher.startY,
					to: this.catcher.startY + BounceGame.config.plankBounceDown,
				},
				ease: "in",
				duration: BounceGame.config.plankBounceDownTime,
				onComplete: () => {
					this.tweens.add({
						targets: [this.catcher],

						y: {
							to: this.catcher!.startY,
							from:
								this.catcher!.startY +
								BounceGame.config.plankBounceDown,
						},
						ease: "in",
						duration: BounceGame.config.plankBounceDownTime,
					});
				},
			});
		}
		// this.fallingObjects.splice(this.fallingObjects.indexOf(fallingObject), 1);
		// fallingObject.destroy();
	}

	hearts: Phaser.GameObjects.Image[] = [];


	static getAspectScale() {
		const scaleX = BounceGame.width / 320;
		const scaleY = BounceGame.height / 570;
		if (scaleY < scaleX) {
			return { x: scaleY, y: scaleY };
		} else if (scaleX < scaleY) {
			return { x: scaleX, y: scaleX };
		} else {
			return { x: scaleX, y: scaleX };
		}
	}

	static getScale() {
		const scaleX = BounceGame.width / 320;
		const scaleY = BounceGame.height / 570;
		return { x: scaleX, y: scaleY };
	}

	playSound(soundKey: string, loop: boolean = false) {
		if (this.isGameOver) {
			return;
		}
		const config = BounceGame.config as any;
		if (config[soundKey + "Sound"])
			this.platform.playSound(
				config.path + "/" + config[soundKey + "Sound"]
			);
	}
}

import { IFallingObject } from "./falling-object";

export interface GameConfig {
	path: string;

	corsFixed: boolean;
	debug: boolean;
	useSVG: boolean;

	splashSprite?: string;
	splashY: number;
	splashScale: number;
	splashOpacity: number;

	objects: IFallingObject[];

	//Sprites
	plankSprite: string;

	plankScale: number;
	plankY: number;
	plankCollisionScaleX: number;
	plankCollisionScaleY: number;
	plankMaxRotation: number;
	plankBounceDown: number;
	plankBounceDownTime: number;
	plankFlip: boolean;

	ballSprite?: string;
	ballScale: number;
	minBallScale?: number;
	maxBallScale?: number;
	ballCollisionScale: number;

	heartSprite: string;
	heartScale: number;

	bonusPerMs: number;
	bouncePoints: number;

	simplePhysics: boolean;

	minBounceHeight: number;
	maxBounceHeight: number;

	speedIncrease: number;
	speedIncreaseInterval: number;
	scoreType: "time" | "points" | "timeandpoints";
	lives?: number;
	ballsCollide: boolean;

	minVelocityHorizontal: number;
	maxVelocityHorizontal: number;

	minVelocityVertical: number;
	maxVelocityVertical: number;

	minFallingObjects: number;
	maxFallingObjects: number;
	objectIncreaseInterval: number;
	spawnDelayMin: number;
	spawnDelayMax: number;

	particleStartAlpha: number;
	particleFrequency: number;

	freezeOnMistake: boolean;
	freezeTime: number;
	flickerAmount: number;

	font: string;
	fontColor: string;
	fontSize: number;
	fontOffsetY: number;

	ballDownSound: string;
	plankBounceSound: string;
	wallBounceSound: string;
	ballsCollideSound: string;
	spawnSound: string;
}

export const _defaultConfig: GameConfig = {
	path: "https://gamecdn.playenwin.com/wietse/catch/default/",

	objects: [],

	corsFixed: true,
	useSVG: false,

	debug: false,

	//Sprites
	plankSprite: "catch3r.png",
	plankY: 10,
	plankScale: 1,
	plankCollisionScaleX: 1,
	plankCollisionScaleY: 1,

	simplePhysics: true,

	lives: undefined,

	splashY: 0.5,

	ballScale: 1,
	ballCollisionScale: 1,

	heartSprite: "ball.png",
	heartScale: 1,

	// Gameplay
	bouncePoints: 1000,

	minBounceHeight: 0.8,
	maxBounceHeight: 1,

	speedIncrease: 0,
	speedIncreaseInterval: 1000,
	scoreType: "timeandpoints",
	ballsCollide: true,

	bonusPerMs: 1,

	minVelocityHorizontal: 10,
	maxVelocityHorizontal: 100,
	minVelocityVertical: 140,
	maxVelocityVertical: 160,

	minFallingObjects: 3,
	maxFallingObjects: 5,
	objectIncreaseInterval: 10000,
	spawnDelayMin: 500,
	spawnDelayMax: 2000,

	particleStartAlpha: 0.1,
	particleFrequency: 10,

	splashScale: 50,
	splashOpacity: 100,


	flickerAmount: 10,
	freezeTime: 500,
	freezeOnMistake: true,

	font: "Source Sans Pro",
	fontColor: "#000000",
	fontSize: 30,
	fontOffsetY: 0,

	plankBounceDown: 20,
	plankBounceDownTime: 1000,
	plankMaxRotation: 30,
	plankFlip: true,

	ballDownSound: "",
	wallBounceSound: "",
	plankBounceSound: "",
	ballsCollideSound: "",
	spawnSound: "",
};

export const fonts: string[] = [
	"RocknRoll One",
	"Lobster",
	"Architects Daughter",
	"UnifrakturMagnutia",
	"Pacifio",
	"Indie Flower",
	"Abril Fatface",
	"Acme",
	"Permanent Marker",
	"Amatic SC",
	"Alfa Slab One",
	"Luckiest Guy",
	"Press Start 2P",
	"Special Elite",
	"Voltaire",
	"Bungee",
	"Black Ops One",
	"Bubblegun Sans",
	"Rammetto One",
	"Wendy One",
	"Freckle Face",
	"Bungee Shade",
	"Henny Penny",
	"Barrio",
	"Flavors",
	"Bonbon",
	"Fredoka One",
	"Source Sans Pro",
];

export function printConfig(config: any) {
	let string = "";
	for (const [key, value] of Object.entries(config)) {
		string += key + "=" + value + "\n";
	}
}
